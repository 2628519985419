import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Icon } from "react-icons-kit"
import { facebookSquare } from "react-icons-kit/fa/facebookSquare"
import { instagram } from "react-icons-kit/fa/instagram"
import NavBar from "../components/NavBar"
import Carousel from "../components/Carousel"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const IndexEN = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "es-ES",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | social media"
        description="Redes sociales"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className="mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-1" />
            <div className="col-span-10">
              <h1>Redes sociales</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8">
              <p>
                <b>Lo que hacemos:</b>
              </p>
              <p>apertura de cuentas</p>
              <p>generación de contenido</p>
              <p>gestión de publicidad</p>
              <div className="h-10" />
              <p>
                <b>Abre tu cuenta</b>
              </p>
              <p>
                Una vez que tengas un website en funcionamiento, tu necesitas
                atraer tráfico.
              </p>
              <p>
                Las redes sociales juegan un papel importante a este respecto y
                es my recomendable la utilización con sabiduría.
              </p>
              <p>
                Es muy común que un usuario de la web revise los enlaces a las
                redes sociales para verificar que está "vivo", es decir, con
                publicaciones periódicas e interacciones con los usuarios.
              </p>
              <p>
                Del mismo modo, alguien que encuentre tu cuenta en las redes
                sociales revisará tu website para obtener una descripción
                general y más información de una manera más formal.
              </p>
              <div className="h-10" />
              <p>
                <b>Mantén tu cuenta activa</b>
              </p>
              <p>
                Nada es peor, en términos de reputación, que una cuenta de redes
                sociales descuidada.
              </p>
              <p>
                Por lo tanto, debes mantener una política de publicación
                regular.
              </p>
              <p>
                Por supuesto, una publicación de imagen atrae mucha más atención
                que el texto, y si esa imagen mantiene la cohesión visual con tu
                website, estás en el camino correcto.
              </p>
              <p>
                Nuestro equipo puede producir este tipo de imágenes y publicar,
                en tu nombre, en sus redes sociales.
              </p>
              <div className="h-10" />
              <p>
                <b>Publicidad</b>
              </p>
              <p>
                Otra posibilidad es anunciar en las redes sociales utilizando un
                alto nivel de detalle para encontrar una audiencia específica.
              </p>
              <p>
                Existen numerosas opciones de valores y cantidad de impresiones
                y podemos ayudarte a encontrar un buen equilibrio entre tu
                presupuesto y la visibilidad deseada.
              </p>
              <div className="h-10" />
              <p>
                <b>Algunas cuentas actualmente atendidas por nosotros</b>
              </p>
              <div className="h-10" />
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className=" text-center">
                  <GatsbyImage
                    image={getImage(data["dauco"])}
                    backgroundColor={false}
                    alt="daucodesigns - Barcelona"
                  />
                  <div className="text-center py-2">
                    <p>daucodesigns</p>
                    <p>Barcelona - España</p>
                  </div>
                  <div className="text-center">
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.facebook.com/daucodesigns"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                    >
                      <Icon size={"25px"} icon={facebookSquare} />
                    </a>
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.instagram.com/dauco.designs/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <Icon size={"25px"} icon={instagram} />
                    </a>
                  </div>
                </div>
                <div className=" text-center">
                  <GatsbyImage
                    image={getImage(data["littlel"])}
                    backgroundColor={false}
                    alt="LittleL - Sydney"
                  />
                  <div className="text-center py-2">
                    <p>LittleL</p>
                    <p>Sydney - Australia</p>
                  </div>
                  <div className="text-center">
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.facebook.com/LittleLMonaValeBurgers"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                    >
                      <Icon size={"25px"} icon={facebookSquare} />
                    </a>
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.instagram.com/littlelmonavale/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <Icon size={"25px"} icon={instagram} />
                    </a>
                  </div>
                </div>
                <div className=" text-center">
                  <GatsbyImage
                    image={getImage(data["spkbr"])}
                    backgroundColor={false}
                    alt="Speckle Park Brasil"
                  />
                  <div className="text-center py-2">
                    <p>Speckle Park Brasil</p>
                    <p>São Paulo - Brasil</p>
                  </div>
                  <div className="text-center">
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.facebook.com/SpeckleParkBrasil"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                    >
                      <Icon size={"25px"} icon={facebookSquare} />
                    </a>
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.instagram.com/speckleparkbrasil/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <Icon size={"25px"} icon={instagram} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-10" />
          <div className="h-10" />
          <div className="max-w-full">
            <div
              className="col-span-12"
              style={{ margin: "0 auto", maxWidth: "500px" }}
            >
              <Carousel
                images={data.socialMediaFiles.edges}
                alt="social media"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-8" />
      <div className="text-center">
        <span className="text-2xl">
          Vamos a trabajar juntos?
          <br />
          <br />
        </span>
        <Link
          className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
          to="/es/lets-work-together/?service=social-media"
        >
          hagamos un proyecto de redes sociales
        </Link>
      </div>
      <div className="h-10" />
      <Footer lang="es" />
    </>
  )
}

export default IndexEN

export const query = graphql`
  {
    socialMediaFiles: allFile(
      filter: { relativePath: { regex: "/social-media/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 500)
          }
        }
      }
    }
    dauco: file(relativePath: { eq: "costumers/Dauco.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 248)
      }
    }
    littlel: file(relativePath: { eq: "costumers/LittleL.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 248)
      }
    }
    spkbr: file(relativePath: { eq: "costumers/SPKBR.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 248)
      }
    }
  }
`
